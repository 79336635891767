import React from 'react';
import { Container } from '../components/container';
import { PseudoBox, Image, Flex, Heading } from '@chakra-ui/core';
import Logo from '../images/logo512.png';
import { LanguageSwitcher } from '../components/language-switcher';
import { Link, useHistory } from 'react-router-dom';
import { Faq } from '../components/faq';

export const FaqEn = ({lang}) => {
  const history = useHistory();
  const handleLang = () => {
    history.push(`/faq`);
  }
  return (
    <Container>
      <Flex justifyContent="space-between" padding="70px 0 30px 0">
        <Link to="/"><Image src={Logo} width="80px" alt=""/></Link>
        <PseudoBox>
          <LanguageSwitcher
            active="en"
            mt={5}
            onClick={handleLang}
          />
        </PseudoBox>
      </Flex>
      <PseudoBox mb="200px">
        <Heading as="h1" size="2xl" mb={10}>
          FAQ
        </Heading>
        <Faq lang={'en'}/>
      </PseudoBox>
    </Container>
  );
};

