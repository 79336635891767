import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box, Code,
  Heading, PseudoBox,
  Spinner
} from '@chakra-ui/core';
import { isEmpty, sortBy } from 'lodash';
import { parseMd } from '../../utils/parseMd';

export const Faq = ({ lang }) => {
  const [faqState, setFaqState] = useState([]);
  useEffect(() => {
    const fetchFaq = async () => {
      try {
        const result = await axios.post('/faq_api', {}, {
          headers: {
            'Content-Type': 'application/json'
          }});
        setFaqState(sortBy(result.data, ['orderNr']));
      } catch (err) {
        console.log(err)
      }
    }
    fetchFaq();
  }, []);
  return (
    <>
      {!isEmpty(faqState) ? faqState?.map(item => {
        if (item.hidden) {
          return null;
        }
        return (
          <PseudoBox key={item.id} marginBottom="60px">
            <Heading as="h3" size="md" mb={8}>{item.chapter[lang]}</Heading>
            <Accordion allowMultiple>
              {item.questions.map((questionItem, index) => (
                <AccordionItem key={index}>
                  <AccordionHeader>
                    <Box flex="1" textAlign="left" fontSize="1.1rem">
                      {questionItem.question[lang]}
                    </Box>
                    <AccordionIcon />
                  </AccordionHeader>
                  <AccordionPanel pb={4}>
                    <div dangerouslySetInnerHTML={{ __html: parseMd(questionItem.answer[lang])}}/>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </PseudoBox>
        )
      }) : (
        <Spinner/>
      )}
    </>
  );
};
