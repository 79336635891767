import React, { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrivacyPolicyRu } from './privacy-policy-ru';
import { PrivacyPolicyEn } from './privacy-policy-en';
import { useFetchAgreement } from './services/useFetchAgreement';
import { useFetchPrivacyPolicy } from './services/useFetchPrivacyPolicy';

const Routes = () => {
  const match = useRouteMatch()
  const { dataAgreement } = useFetchAgreement()
  const { dataPrivacyPolicy } = useFetchPrivacyPolicy()

  const data = useMemo(() => {
    return {
      agreement: dataAgreement,
      privacyPolicy: dataPrivacyPolicy
    }
  }, [dataAgreement, dataPrivacyPolicy])

  return (
    <Switch>
      <Route
        path={`${match.url}/en`}
      >
        <PrivacyPolicyEn data={data}/>
      </Route>
      <Route
        path={`${match.url}`}
      >
        <PrivacyPolicyRu data={data}/>
      </Route>
    </Switch>
  );
};

export default Routes;
