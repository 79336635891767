import React from 'react';
import { Paragraph } from '../../components/paragraph';
import { Heading, PseudoBox } from '@chakra-ui/core';
import { parseText } from '../../utils/parseText';

export const ContentUserAgreementEn = ({ data }) => {
  return (
    <>
      <PseudoBox
        dangerouslySetInnerHTML={parseText(data?.value?.en)}
      />
    </>
  )
};
