import React, { useState } from 'react';
import { Flex, Image, PseudoBox } from '@chakra-ui/core';
import Phone from '../../images/iphone.png';
//import QR from '../../images/qr2.png';
import Cover from '../../images/cover-3.png';
import CoverEn from '../../images/app-en.png';
import bgMp4 from '../../videos/bg.mp4';
// import bgOgv from '../../videos/bg.ogv';

export const DeviceVideo = ({ lang }) => {
    const [activeVideo, setActiveVideo] = useState(false);

   /* useEffect(() => {
        let timer1 = setTimeout(() => setActiveVideo(true), 1500)
        return () => {
            clearTimeout(timer1);
        }
    });*/

    const TYPE_COVER = {
        ru: Cover,
        en: CoverEn
    }


    return (
       <PseudoBox
           role="group"
           position="relative"
           cursor="pointer"
           width={{
               md: '287px',
               xs: '194px'
           }}
           height={{
               md: '580px',
               xs: '386px'
           }}
           onClick={() => setActiveVideo(e => !e) }
           transition="0.3s"
           _hover={{
               //transform: 'translate3d(0,-4px,0)'
           }}
       >
           <PseudoBox
               position="absolute"
               zIndex={6}
               top={0}
               pointerEvents="none"
           >
               <Image
                   src={Phone}
                   alt=""
               />
           </PseudoBox>
          {/* <Flex
               alignItems="flex-end"
               justifyContent="center"
               paddingBottom="50px"
               width="100%"
               height="100%"
               //backgroundColor="#04022d"
               borderRadius={70}
               position="absolute"
               top={0}
               left={0}
               zIndex={5}
               opacity="1"
           >
               <PseudoBox
                   width="70%"
                   transition="0.5s"
                   _groupHover={{
                       opacity: '0'
                   }}
               >
                   <Image src={QR} alt="" />
               </PseudoBox>
           </Flex>*/}
           <PseudoBox
               width="100%"
               height="100%"
               //backgroundColor="rgba(4,2,45,0.5)"
               borderRadius={70}
               position="absolute"
               top={0}
               left={0}
               zIndex={4}
               transition="0.5s"
               opacity="1"
               _groupHover={{
                   //backgroundColor: 'rgba(4,2,45,0)'
               }}
           >
               <PseudoBox
                   padding={{
                       md: '0.73rem 0.92rem 0px',
                       xs: '8px 10px 0px'
                   }}
                   transition="opacity 0.5s"
                   opacity={!activeVideo ? 1 : 0}
               >
                   <Image src={TYPE_COVER[lang]} alt="" />
               </PseudoBox>
           </PseudoBox>
           <Flex
               position="relative"
               padding={{
                   md: '12px 14px 0px',
                   xs: '7px 10px 0px'
               }}
               height="100%"
               borderRadius={{
                   md: 70,
                   xs: 30
               }}
               overflow="hidden"
           >
               {activeVideo && (
                   <Flex width="100%" height="100%" backgroundColor="black" alignItems="center">
                       <video playsInline autoPlay loop>
                           <source src={bgMp4} type="video/mp4" />
                           Your browser does not support the video tag.
                       </video>
                   </Flex>
               )}
           </Flex>
       </PseudoBox>
    )
}
