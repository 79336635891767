import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Main } from './main';

const Routes = () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route
                path={`/en`}
                exact
            >
                <Main lang="en"/>
            </Route>
            <Route
                path={`/`}
                exact
            >
                <Main lang="ru"/>
            </Route>
        </Switch>
    );
};

export default Routes;
