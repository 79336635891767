import React from 'react';
import { ThemeProvider, theme, CSSReset } from '@chakra-ui/core';

const customIcons = {
    list: {
        path: (
            <path
                fill="currentColor"
                clipRule="evenodd"
                fillRule="evenodd"
                d="M1.08,0A1.08,1.08,0,0,0,0,1.08V13.64a1.08,1.08,0,0,0,1.08,1.08H11.44a1.07,1.07,0,0,0,1.07-1.08V1.08A1.07,1.07,0,0,0,11.44,0ZM9.93,4.36H2.5A.55.55,0,0,1,2,3.81a.55.55,0,0,1,.55-.55H9.93a.54.54,0,0,1,.55.55A.55.55,0,0,1,9.93,4.36ZM2.55,7.83H10a.55.55,0,0,0,.55-.55A.55.55,0,0,0,10,6.73H2.55A.55.55,0,0,0,2,7.28.55.55,0,0,0,2.55,7.83Zm4.76,3.48H2.55A.55.55,0,0,1,2,10.76a.55.55,0,0,1,.55-.55H7.31a.55.55,0,0,1,.55.55A.55.55,0,0,1,7.31,11.31Z"            />
        ),
        viewBox: '0 0 12 14'
    },
    geo: {
        path: (
            <path
                fill="currentColor"
                clipRule="evenodd"
                fillRule="evenodd"
                d="M8 16s6-5.686 6-10A6 6 0 002 6c0 4.314 6 10 6 10zm0-7a3 3 0 100-6 3 3 0 000 6z"
            />
        ),
        viewBox: '0 0 16 16'
    },
    play: {
        path: <path fill="currentColor" d="M0 0v6l6-3-6-3z" transform="translate(1 1)" />,
        viewBox: '0 0 8 8'
    },
    people: {
        path: (
            <path
                fill="currentColor"
                clipRule="evenodd"
                fillRule="evenodd"
                d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 100-6 3 3 0 000 6zm-5.784 6A2.238 2.238 0 015 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 005 9c-4 0-5 3-5 4s1 1 1 1h4.216zM4.5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
            />
        ),
        viewBox: '0 0 16 16'
    },
    exit: {
        path: (
            <>
                <path
                    fill="currentColor"
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                />
                <path
                    fill="currentColor"
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                />
            </>
        ),
        viewBox: '0 0 16 16'
    },
    sovoc: {
        path: (
            <path fill="currentColor" clipRule="evenodd" fillRule="evenodd" d="M8,0h.32V3H8A5,5,0,0,0,8,13h.32v3H8A8,8,0,0,1,8,0ZM9.61.16a8,8,0,0,1,0,15.68h0V11.68H8A3.68,3.68,0,0,1,8,4.32h1.6Z"/>
        ),
        viewBox: '0 0 16 16'
    },
    layers: {
        path: (
            <g fill="currentColor" clipRule="evenodd" fillRule="evenodd">
                <path
                    fillRule="evenodd"
                    d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882l7.5-4z"
                />
                <path
                    fillRule="evenodd"
                    d="M2.125 8.567l-1.86.992a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882l-1.86-.992-5.17 2.756a1.5 1.5 0 0 1-1.41 0l.418-.785-.419.785-5.169-2.756z"
                />
            </g>
        ),
        viewBox: '0 0 16 16'
    },
    collection: {
        path: (
            <g fill="currentColor" clipRule="evenodd" fillRule="evenodd">
                <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7z" />
                <path d="M2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />
            </g>
        ),
        viewBox: '0 0 16 16'
    },
    image: {
        path: (
            <g fill="currentColor" clipRule="evenodd" fillRule="evenodd">
                <path fillRule="evenodd" d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                <path d="M10.648 7.646a.5.5 0 0 1 .577-.093L15.002 9.5V13h-14v-1l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71z"/>
                <path fillRule="evenodd" d="M4.502 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
            </g>
        ),
        viewBox: '0 0 16 16'
    },
    componentHeaderLite: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none">
                <polygon fill="#000000" points="13.5659992 41.2679975 13.5659992 51.8459969 25.7939985 51.8459969 25.7939985 41.2679975 29.0939983 41.2679975 29.0939983 66.545996 25.7939985 66.545996 25.7939985 54.7019967 13.5659992 54.7019967 13.5659992 66.551996 10.3079994 66.551996 10.3079994 41.2679975"/>
                <polygon fill="#000000" points="35.9159979 66.545996 35.9159979 54.2099968 35.8439979 54.2099968 33.143998 55.5179967 32.735998 53.5679968 36.2639978 51.9179969 38.5499977 51.9179969 38.5499977 66.545996"/>
                <path stroke="#4299E1" strokeWidth="2" d="M46.8179972,-4.26325616e-15 L1.6979999,-4.26325616e-15 C0.760199955,-4.26325616e-15 3.83693054e-14,0.755878674 3.83693054e-14,1.68834779 L3.83693054e-14,33.2956502 C3.83693054e-14,34.2281193 0.760199955,34.983998 1.6979999,34.983998 L46.8179972,34.983998 C47.7557972,34.983998 48.5159971,34.2281193 48.5159971,33.2956502 L48.5159971,1.68834779 C48.5159971,0.755878674 47.7557972,-4.26325616e-15 46.8179972,-4.26325616e-15 Z"/>
                <polygon fill="#A4C5DF" points="3.4199998 34.5899979 12.8939992 17.7839989 19.9919988 28.9559983 23.5739986 34.5899979"/>
                <polygon fill="#4299E1" points="29.5139982 9.91199941 45.0899973 34.5899979 14.6579991 34.5899979"/>

            </g>
        ),
        viewBox: '0 0 50 70'
    },
    componentImage: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none">
                <polygon fill="#A4C5DF" points="4.25879997 43.2116997 16.0649999 22.2767998 24.9101998 36.1934997 29.3705998 43.2116997"/>
                <polygon fill="#4299E1" points="36.7667997 12.4676999 56.1770996 43.2116997 18.2573999 43.2116997"/>
                <path stroke="#4299E1" strokeWidth="2" d="M58.6592996,0 L1.78289999,0 C0.798272994,0 0,0.794567625 0,1.77476431 L0,41.8543357 C0,42.8345324 0.798272994,43.6291 1.78289999,43.6291 L58.6592996,43.6291 C59.6439895,43.6291 60.4421995,42.8345324 60.4421995,41.8543357 L60.4421995,1.77476431 C60.4421995,0.794567625 59.6439895,0 58.6592996,0 Z"/>
            </g>
        ),
        viewBox: '0 0 62 46'
    },
    componentTextImage: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none">
                <polygon fill="#A4C5DF" points="39.372979 37.384021 46.652538 24.470896 52.107042 33.058985 54.854955 37.384021"/>
                <polygon fill="#4299E1" points="59.421036 18.42411 71.3906419 37.384021 48.0023211 37.384021"/>
                <path stroke="#4299E1" strokeWidth="2" d="M69.8204059,10.807088 L41.322,10.807088 C40.2456307,10.807088 39.372979,11.6796709 39.372979,12.756109 L39.372979,35.889542 C39.372979,36.9659801 40.2456307,37.838563 41.322,37.838563 L69.8204059,37.838563 C70.896844,37.838563 71.7694269,36.9659801 71.7694269,35.889542 L71.7694269,12.756109 C71.7694269,11.6796709 70.896844,10.807088 69.8204059,10.807088 Z"/>
                <path fill="#A4C5DF" d="M27.20365,0 L0.509638,0 C0.22816631,0 -7.34026173e-14,0.2279597 -7.34026173e-14,0.509638 L-7.34026173e-14,2.045439 C-7.34026173e-14,2.3271173 0.22816631,2.555077 0.509638,2.555077 L27.20365,2.555077 C27.4851217,2.555077 27.713288,2.3271173 27.713288,2.045439 L27.713288,0.509638 C27.713288,0.2279597 27.4851217,0 27.20365,0 Z"/>
                <path fill="#A4C5DF" d="M27.20365,4.793352 L0.509638,4.793352 C0.22816631,4.793352 -7.34026173e-14,5.0213117 -7.34026173e-14,5.30299 L-7.34026173e-14,6.838791 C-7.34026173e-14,7.12046929 0.22816631,7.34842899 0.509638,7.34842899 L27.20365,7.34842899 C27.4851217,7.34842899 27.713288,7.12046929 27.713288,6.838791 L27.713288,5.30299 C27.713288,5.0213117 27.4851217,4.793352 27.20365,4.793352 Z" />
                <path fill="#A4C5DF" d="M27.20365,9.58670399 L0.509638,9.58670399 C0.22816631,9.58670399 -7.34026173e-14,9.81466369 -7.34026173e-14,10.096342 L-7.34026173e-14,11.632143 C-7.34026173e-14,11.9138213 0.22816631,12.141781 0.509638,12.141781 L27.20365,12.141781 C27.4851217,12.141781 27.713288,11.9138213 27.713288,11.632143 L27.713288,10.096342 C27.713288,9.81466369 27.4851217,9.58670399 27.20365,9.58670399 Z" />
                <path fill="#A4C5DF" d="M27.20365,14.380056 L0.509638,14.380056 C0.22816631,14.380056 -7.34026173e-14,14.6080157 -7.34026173e-14,14.889694 L-7.34026173e-14,16.425495 C-7.34026173e-14,16.7071733 0.22816631,16.935133 0.509638,16.935133 L27.20365,16.935133 C27.4851217,16.935133 27.713288,16.7071733 27.713288,16.425495 L27.713288,14.889694 C27.713288,14.6080157 27.4851217,14.380056 27.20365,14.380056 Z"/>
                <path fill="#A4C5DF" d="M27.20365,18.842832 L0.509638,18.842832 C0.22816631,18.842832 -7.34026173e-14,19.0707917 -7.34026173e-14,19.35247 L-7.34026173e-14,20.888271 C-7.34026173e-14,21.1699493 0.22816631,21.397909 0.509638,21.397909 L27.20365,21.397909 C27.4851217,21.397909 27.713288,21.1699493 27.713288,20.888271 L27.713288,19.35247 C27.713288,19.0707917 27.4851217,18.842832 27.20365,18.842832 Z"/>
                <path fill="#A4C5DF" d="M27.20365,23.305608 L0.509638,23.305608 C0.22816631,23.305608 -7.34026173e-14,23.5335677 -7.34026173e-14,23.815246 L-7.34026173e-14,25.351047 C-7.34026173e-14,25.6327253 0.22816631,25.860685 0.509638,25.860685 L27.20365,25.860685 C27.4851217,25.860685 27.713288,25.6327253 27.713288,25.351047 L27.713288,23.815246 C27.713288,23.5335677 27.4851217,23.305608 27.20365,23.305608 Z"/>
                <path fill="#A4C5DF" d="M27.20365,28.09896 L0.509638,28.09896 C0.22816631,28.09896 -7.34026173e-14,28.3269197 -7.34026173e-14,28.608598 L-7.34026173e-14,30.144399 C-7.34026173e-14,30.4260773 0.22816631,30.654037 0.509638,30.654037 L27.20365,30.654037 C27.4851217,30.654037 27.713288,30.4260773 27.713288,30.144399 L27.713288,28.608598 C27.713288,28.3269197 27.4851217,28.09896 27.20365,28.09896 Z"/>
                <path fill="#A4C5DF" d="M27.20365,32.892312 L0.509638,32.892312 C0.22816631,32.892312 -7.34026173e-14,33.1202717 -7.34026173e-14,33.40195 L-7.34026173e-14,34.937751 C-7.34026173e-14,35.2194293 0.22816631,35.447389 0.509638,35.447389 L27.20365,35.447389 C27.4851217,35.447389 27.713288,35.2194293 27.713288,34.937751 L27.713288,33.40195 C27.713288,33.1202717 27.4851217,32.892312 27.20365,32.892312 Z"/>
                <path fill="#A4C5DF" d="M27.20365,37.685664 L0.509638,37.685664 C0.22816631,37.685664 -7.34026173e-14,37.9136237 -7.34026173e-14,38.195302 L-7.34026173e-14,39.731103 C-7.34026173e-14,40.0127813 0.22816631,40.240741 0.509638,40.240741 L27.20365,40.240741 C27.4851217,40.240741 27.713288,40.0127813 27.713288,39.731103 L27.713288,38.195302 C27.713288,37.9136237 27.4851217,37.685664 27.20365,37.685664 Z" />
                <path fill="#A4C5DF" d="M27.20365,42.485903 L0.509638,42.485903 C0.22816631,42.485903 -7.34026173e-14,42.7138627 -7.34026173e-14,42.995541 L-7.34026173e-14,44.531342 C-7.34026173e-14,44.8130203 0.22816631,45.04098 0.509638,45.04098 L27.20365,45.04098 C27.4851217,45.04098 27.713288,44.8130203 27.713288,44.531342 L27.713288,42.995541 C27.713288,42.7138627 27.4851217,42.485903 27.20365,42.485903 Z" />
                <path fill="#A4C5DF" d="M27.20365,46.941792 L0.509638,46.941792 C0.22816631,46.941792 -7.34026173e-14,47.1697517 -7.34026173e-14,47.45143 L-7.34026173e-14,48.987231 C-7.34026173e-14,49.2689093 0.22816631,49.496869 0.509638,49.496869 L27.20365,49.496869 C27.4851217,49.496869 27.713288,49.2689093 27.713288,48.987231 L27.713288,47.45143 C27.713288,47.1697517 27.4851217,46.941792 27.20365,46.941792 Z" />
            </g>
        ),
        viewBox: '0 0 73 50'
    },
    componentIndent: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none">
                <path fill="#A4C5DF" fillRule="nonzero" d="M47.4860991,20.1653996 L1.44329997,20.1653996 C0.646169988,20.1653996 7.24753577e-15,20.8115696 7.24753577e-15,21.6086996 L7.24753577e-15,32.6297994 C7.24753577e-15,33.4269294 0.646169988,34.0730994 1.44329997,34.0730994 L47.4860991,34.0730994 C48.2832291,34.0730994 48.9293991,33.4269294 48.9293991,32.6297994 L48.9293991,21.6086996 C48.9293991,20.8115696 48.2832291,20.1653996 47.4860991,20.1653996 Z"/>
                <line stroke="#4299E1" strokeWidth="2" strokeLinecap="round" x1="24.4646995" y1="0.866999984" x2="24.4646995" y2="15.7334997"/>
                <polyline stroke="#4299E1" strokeWidth="2" strokeLinecap="round" points="29.0393995 6.06899989 24.4391995 -7.24753577e-15 19.8899996 6.04349989"/>
                <line x1="24.4646995" y1="53.376599" x2="24.4646995" y2="38.5049993" stroke="#4299E1" strokeWidth="2" strokeLinecap="round"/>
                <polyline stroke="#4299E1" strokeWidth="2" strokeLinecap="round" points="19.8899996 48.1745991 24.4952995 54.238499 29.0393995 48.2000991"/>
            </g>
        ),
        viewBox: '0 0 49 57'
    },
    componentFooter: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none">
                <rect fill="#A5C5DF" x="0.12" y="1.53" width="46.28" height="3.71" rx="0.74"/>
                <rect fill="#A5C5DF" x="0.12" y="8.49" width="46.28" height="3.71" rx="0.74"/>
                <circle fill="#4299E1" cx="69.22" cy="6.87" r="6.8"/>
                <circle fill="#4299E1" cx="90.17" cy="6.87" r="6.8"/>
                <circle fill="#4299E1" cx="110.95" cy="6.87" r="6.8"/>
            </g>
        ),
        viewBox: '0 0 120 14'
    },
    componentFooterCorporate: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none">
                <polygon fill="#A5C5DF" fillRule="nonzero" points="0.17 42.71 7.67 29.41 13.29 38.25 16.12 42.71"/>
                <polygon fill="#4299E1" fillRule="nonzero" points="20.82 23.17 33.16 42.71 9.06 42.71"/>
                <rect stroke="#4299E1" strokeWidth="2" x="0.17" y="15.33" width="33.38" height="27.85" rx="2.83"/>
                <polygon fill="#A5C5DF" fillRule="nonzero" points="42.18 42.71 49.68 29.41 55.3 38.25 58.13 42.71"/>
                <polygon fill="#4299E1" fillRule="nonzero" points="62.83 23.17 75.17 42.71 51.07 42.71"/>
                <rect stroke="#4299E1" strokeWidth="2" x="42.18" y="15.33" width="33.38" height="27.85" rx="2.83"/>
                <polygon fill="#A5C5DF" fillRule="nonzero" points="84.5 42.86 92 29.56 97.63 38.4 100.46 42.86"/>
                <polygon fill="#4299E1" fillRule="nonzero" points="105.16 23.32 117.5 42.86 93.4 42.86"/>
                <rect stroke="#4299E1" strokeWidth="2" x="84.5" y="15.48" width="33.38" height="27.85" rx="2.83"/>
                <rect fill="#A5C5DF" fillRule="nonzero" x="0.17" y="0.4" width="32.99" height="4.98" rx="0.74"/>
                <rect fill="#A5C5DF" fillRule="nonzero" x="42.06" y="0.4" width="32.99" height="4.98" rx="0.74"/>
                <rect fill="#A5C5DF" fillRule="nonzero" x="83.96" y="0.4" width="32.99" height="4.98" rx="0.74"/>
            </g>
        ),
        viewBox: '0 0 120 34'
    },
    componentTextLeftIndent: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none">
                <rect fill="#A5C5DF" x="49.36" y="0.33" width="67.44" height="3.71" rx="0.74"/>
                <rect fill="#A5C5DF" x="49.36" y="7.29" width="67.44" height="3.71" rx="0.74"/>
                <rect fill="#A5C5DF" x="49.36" y="14.26" width="67.44" height="3.71" rx="0.74"/>
                <rect fill="#A5C5DF" x="49.36" y="21.22" width="67.44" height="3.71" rx="0.74"/>
                <rect fill="#A5C5DF" x="49.36" y="27.69" width="40.24" height="3.71" rx="0.74"/>
                <rect fill="#A5C5DF" x="0.39" y="0.33" width="43.67" height="31.07" rx="2.83"/>
            </g>
        ),
        viewBox: '0 0 120 34'
    },
    iconMenu: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd">
                <path d="M2,0 C3.1045695,0 4,0.8954305 4,2 C4,3.1045695 3.1045695,4 2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0 Z M10,0 C11.1045695,0 12,0.8954305 12,2 C12,3.1045695 11.1045695,4 10,4 C8.8954305,4 8,3.1045695 8,2 C8,0.8954305 8.8954305,0 10,0 Z M18,0 C19.1045695,0 20,0.8954305 20,2 C20,3.1045695 19.1045695,4 18,4 C16.8954305,4 16,3.1045695 16,2 C16,0.8954305 16.8954305,0 18,0 Z"/>
            </g>
        ),
        viewBox: '0 0 20 4'
    },
    logo: {
        path: (
            <g clipRule="evenodd" fillRule="evenodd" fill="none" strokeWidth="2">
                <path stroke="#000000" strokeWidth="2" d="M107.41,13.08 C105.85,11.59 102.76,7.54 99.07,7.5 C94.4,7.5 91.07,11.5 88.47,9.5 C81.67,4.45 68.19,1.3 59.3,0.33 C58.74,0.26 58.18,0.21 57.62,0.16 L56.83,0.1 L56,0.05 C55.35,0 54.73,0 54.18,0 L53.88,0 C53.33,0 52.71,0 52.05,0.05 L51.23,0.1 L50.44,0.16 C49.88,0.21 49.32,0.26 48.76,0.33 C39.87,1.33 26.39,4.45 19.59,9.51 C16.97,11.51 13.66,7.51 8.99,7.51 C5.3,7.51 2.21,11.6 0.65,13.09 C-2.63,16.24 7.55,16.98 8.49,21.47 C10.06,28.96 7.82,37.9 7.82,46.07 C7.825522,71.5043302 28.4456692,92.12 53.88,92.12 L54.18,92.12 C79.6182356,92.12 100.24,71.4982356 100.24,46.06 C100.24,37.89 98,28.95 99.57,21.46 C100.51,17 110.69,16.23 107.41,13.08 Z"/>
                <path stroke="#000000" strokeWidth="2" fill="#FFFFFF" fillRule="nonzero"d="M103.12,114.69 L4.94,114.69 C2.40246928,114.581836 0.432789544,112.437571 0.54,109.9 L0.54,55.58 C0.530526847,54.3283675 0.990841984,53.1187021 1.83,52.19 C2.62096553,51.3018091 3.75070727,50.7896111 4.94,50.78 L103.12,50.78 C105.659084,50.8935133 107.627288,53.0406452 107.52,55.58 L107.52,109.9 C107.62721,112.437571 105.657531,114.581836 103.12,114.69 Z"/>
                <path stroke="#000000" strokeWidth="2" fill="#FFFFFF" fillRule="nonzero" d="M1.83,52.19 L53.53,83.69 C54.3795717,84.2245704 55.4604283,84.2245704 56.31,83.69 L105.7,51.7"/>
                <path fill="#4299E1" fillRule="nonzero" d="M55.19,20.25 C54.7906504,20.8101135 54.4266109,21.3945806 54.1,22 C50.2939008,14.7209725 42.1080683,10.8650541 34.0720334,12.5658703 C26.0359985,14.2666866 20.1143148,21.1084423 19.5836738,29.3053352 C19.0530328,37.5022281 24.0431594,45.0503799 31.7928277,47.7731128 C39.542496,50.4958456 48.1571599,47.7275383 52.87,41 C53.2871217,40.4263681 53.6646631,39.8249746 54,39.2 C57.8060992,46.4790275 65.9919317,50.3349459 74.0279666,48.6341297 C82.0640015,46.9333134 87.9856852,40.0915577 88.5163262,31.8946648 C89.0469672,23.6977719 84.0568406,16.1496201 76.3071723,13.4268872 C68.557504,10.7041544 59.9428401,13.4724617 55.23,20.2 L55.19,20.25 Z"/>
                <path fill="#FFFFFF" fillRule="nonzero" d="M55.13,21.86 C54.8072942,22.3268597 54.5101897,22.8109063 54.24,23.31 C51.117371,17.3232854 44.3897241,14.1490244 37.7835509,15.5454629 C31.1773778,16.9419014 26.3098164,22.5672024 25.8770438,29.3054717 C25.4442712,36.0437409 29.5522267,42.2455042 35.9254843,44.4755606 C42.2987419,46.705617 49.3771795,44.4180666 53.24,38.88 C53.5681782,38.4112766 53.8686903,37.9237793 54.14,37.42 C57.2596379,43.3778416 63.9633932,46.5311388 70.542095,45.13518 C77.1207967,43.7392213 81.9663135,38.1352418 82.3976331,31.4239096 C82.8289526,24.7125773 78.740641,18.5344444 72.3947507,16.3078563 C66.0488605,14.0812682 58.9966133,16.3505036 55.14,21.86 L55.13,21.86 Z"/>
                <path fill="#000000" fillRule="nonzero" d="M50.76,28.65 C49.6940385,30.0522138 47.9468995,30.7596416 46.2056476,30.4940865 C44.4643957,30.2285314 43.0075543,29.0324705 42.408028,27.3762562 C41.8085017,25.7200419 42.1621883,23.8685952 43.33,22.55 C44.0077711,21.7177338 44.9499884,21.1425225 46,20.92 C41.6456738,18.3173711 36.0659269,19.1027664 32.5992404,22.8062672 C29.1325539,26.5097681 28.7170402,32.1291781 31.60131,36.302285 C34.4855799,40.475392 39.8891272,42.0729204 44.578895,40.1390351 C49.2686627,38.2051499 51.975563,33.2631754 51.08,28.27 C51,28.4 50.86,28.53 50.76,28.65 Z"/>
                <path fill="#000000" fillRule="nonzero" d="M77.36,28.43 C76.0267481,30.0551136 73.7926507,30.6262469 71.8430453,29.8403758 C69.8934399,29.0545047 68.6799565,27.0936776 68.846429,24.9982441 C69.0129014,22.9028106 70.5207659,21.158182 72.57,20.69 C68.206799,18.0847891 62.6171649,18.8771838 59.1499632,22.5924441 C55.6827615,26.3077044 55.2778899,31.9386882 58.1779155,36.1117564 C61.077941,40.2848246 66.4967175,41.8687316 71.1877436,39.9145257 C75.8787697,37.9603199 78.5703904,32.9977523 77.65,28 C77.5638568,28.1501563 77.4669359,28.2938667 77.36,28.43 Z" />
                <path fill="#000000" fillRule="nonzero" d="M58.83,45.23 C59.3,40.99 54.92,37.48 53.83,37.52 C52.74,37.56 48.51,41.41 49.01,45.47 C49.38,48 51.5,51.75 52.88,54 C53.127537,54.4128734 53.5736072,54.6655321 54.055,54.6655321 C54.5363928,54.6655321 54.982463,54.4128734 55.23,54 C56.54,51.67 58.54,47.87 58.83,45.23 Z"/>
                <circle stroke="#000000" strokeWidth="2" fill="#FFFFFF" fillRule="nonzero" cx="54.03" cy="87.78" r="16.48"/>
                <g transform="translate(40.000000, 74.000000)" fillRule="nonzero">
                    <path fill="#A4C5DF" d="M16.66,0.13 L16.66,7.36 L13.9,7.36 C10.4664647,7.49580199 7.7529328,10.3187801 7.7529328,13.755 C7.7529328,17.1912199 10.4664647,20.014198 13.9,20.15 L16.66,20.15 L16.66,27.38 C23.0750103,25.992621 27.6533112,20.3183206 27.6533112,13.755 C27.6533112,7.19167937 23.0750103,1.51737902 16.66,0.13"/>
                    <path fill="#4299E1" d="M13.66,22.22 C8.98767102,22.22 5.2,18.432329 5.2,13.76 C5.2,9.08767102 8.98767102,5.3 13.66,5.3 L14.17,5.3 L14.17,0.15 L13.66,0.15 C6.33539469,0.430627961 0.542206856,6.4500208 0.542206856,13.78 C0.542206856,21.1099792 6.33539469,27.129372 13.66,27.41 L14.17,27.41 L14.17,22.22 L13.66,22.22 Z"/>
                </g>

            </g>
        ),
        viewBox: '0 0 112 117'
    }
};

const customTheme = {
    ...theme,
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem",
    },
    icons: {
        ...theme.icons,
        ...customIcons
    },
};

export const Theme = ({ children }) => (
    <ThemeProvider theme={customTheme}>
        <CSSReset />
        {children}
    </ThemeProvider>
);
