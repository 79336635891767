import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Theme } from './theme';
import {
    BrowserRouter as Router
} from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';

ReactDOM.render(
    <React.Fragment>
        <YMInitializer accounts={[71618392]} options={{webvisor: true}} version="2"  />
        <Theme>
            <Router>
                <App/>
            </Router>
        </Theme>
    </React.Fragment>,
    document.getElementById('root')
);
