export const text = {
    ru: {
        title: 'Путешествую без Covid-19',
        subTitle: 'Поиск лабораторий, получение результатов тестов и выезд за границу',
        faqLink: 'Вопросы и ответы'
    },
    en: {
        title: 'Covid-19 Free Travel',
        subTitle: 'Searching laboratories, getting test results and traveling abroad',
        faqLink: 'FAQ'
    }
}
