import { useEffect } from 'react'
import useQuery from '../../hooks/useQuery'
import { useToast } from '@chakra-ui/core'

const apiData = async () => {
  const response = await fetch(`/adminapi/legal-information/privacy_policy`)
  return await response.json()
}

export const useFetchPrivacyPolicy = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    request,
    response
  } = useQuery(() => apiData())

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    request()
  }, [])

  return {
    dataPrivacyPolicy: response
  }
}
