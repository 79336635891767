import React from 'react';
import { PseudoBox } from '@chakra-ui/core';

export const Container = ({ children, size = 'md', ...props }) => {
    const WIDTH = {
        'md': '880px',
        'xs': '700px'
    }
    return (
        <PseudoBox
            marginLeft="auto"
            marginRight="auto"
            paddingLeft="30px"
            paddingRight="30px"
            maxWidth={WIDTH[size]}
            {...props}
        >
            {children}
        </PseudoBox>
    );
};
