import React from 'react';
import { Flex, PseudoBox } from '@chakra-ui/core';

const Item = ({ active, lang, onClick, children }) => {
    const handleClick = () => {
        onClick(lang)
    }
    return (
        <PseudoBox
            borderRadius={4}
            padding="6px 8px"
            backgroundColor={active === lang && 'gray.100'}
            color={active === lang && 'blue.500'}
            fontSize="0.9rem"
            ml={1}
            mr={1}
            cursor="pointer"
            transition="0.2s"
            _hover={{
                color: 'blue.400'
            }}
            onClick={handleClick}
        >
            {children}
        </PseudoBox>
    )
}

export const LanguageSwitcher = ({ active, onClick, ...props }) => {
    return (
        <Flex {...props}>
            <Item active={active} onClick={onClick} lang="ru">RU</Item>
            <Item active={active} onClick={onClick} lang="en">EN</Item>
        </Flex>
    )
}
