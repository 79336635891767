import React from 'react';
import { Container } from '../components/container';
import { PseudoBox, Image, Flex } from '@chakra-ui/core';
import Logo from '../images/logo512.png';
import { ContentPrivacyPolicyRu } from './modules/content-privacy-policy-ru';
import { ContentUserAgreementRu } from './modules/content-user-agreement-ru';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/core';
import { useHistory } from 'react-router-dom';
import { LanguageSwitcher } from '../components/language-switcher';

export const PrivacyPolicyRu = ({ data }) => {
  const history = useHistory();
  const handleLang = () => {
    history.push(`/privacy-policy/en`);
  }
  return (
    <Container>
      <Flex justifyContent="space-between" padding="70px 0 30px 0">
        <Image src={Logo} width="80px" alt=""/>
        <PseudoBox>
          <LanguageSwitcher
            active="ru"
            mt={5}
            onClick={handleLang}
          />
        </PseudoBox>
      </Flex>
      <PseudoBox mb="200px">
        <Tabs variant="enclosed">
          <TabList>
            <Tab>Обработка персональных данных</Tab>
            <Tab>Пользовательское соглашение</Tab>
          </TabList>

          <TabPanels pt="30px">
            <TabPanel>
              <ContentPrivacyPolicyRu data={data?.privacyPolicy}/>
            </TabPanel>
            <TabPanel>
              <ContentUserAgreementRu data={data?.agreement}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PseudoBox>
    </Container>
  );
};

