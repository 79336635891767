import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import PrivacyPolicy from './privacy-policy';
import Main from './main';
import Faq from './faq';

function App() {
    return (
        <Box width="100%">
            <Switch>
                <Route path="/faq">
                    <Faq/>
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicy/>
                </Route>
                <Route path="/">
                    <Main/>
                </Route>
            </Switch>
        </Box>
    );
}

export default App;
