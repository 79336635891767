import React from 'react';
import { Image, PseudoBox, Link } from '@chakra-ui/core'
import ImageAppStore from './appstore.png'
import ImageGooglePlay from './googleplay.png'
import Huawei from './huawei-logo.png'

export const AppButton = ({ link, type, ...props }) => {
    return (
        <Link href={link} isExternal>
            <PseudoBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#292c2f"
                borderRadius={{
                    md: 8,
                    xs: 4
                }}
                width={{
                    md: '140px',
                    xs: '120px'
                }}
                height={{
                    md: '50px',
                    xs: '46px'
                }}
                cursor="pointer"
                transition="0.3s"
                _hover={{
                    backgroundColor: '#4541B1'
                }}
                _active={{
                    backgroundColor: '#333086'
                }}
                {...props}
            >
                <PseudoBox>
                    {type === 'appstore' ? (
                        <Image
                            src={ImageAppStore}
                            width={{
                                md: '110px',
                                xs: '90px'
                            }}
                            height="auto"
                        />
                    ) : type === 'googleplay' ? (
                        <Image
                            src={ImageGooglePlay}
                            width={{
                                md: '110px',
                                xs: '90px'
                            }}
                            height="auto"
                        />
                    ) : (
                        <Image
                            src={Huawei}
                            width={{
                                md: '110px',
                                xs: '90px'
                            }}
                            height="auto"
                        />
                    )}
                </PseudoBox>
            </PseudoBox>
        </Link>

    )
}
