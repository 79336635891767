import React from 'react';
import { Container } from '../components/container';
import { Flex, Heading, Image, Grid, PseudoBox, Text, Link } from '@chakra-ui/core';
import Logo from '../images/logo512.png';
import { AppButton } from '../components/app-button/app-button';
//import { useWindowHeight } from '../components/use-window-height';
import { DeviceVideo } from '../components/device-video/device-video';
import { getTranslation } from '../utils/getTranslation';
import { LanguageSwitcher } from '../components/language-switcher';
import { useHistory, Link as LinkRouter } from 'react-router-dom';

export const Main = ({lang}) => {
  // const height = useWindowHeight();
  const handleLang = () => {
    if (lang === 'ru') {
      history.push(`/en`);
    } else {
      history.push(`/`);
    }
  }
  const history = useHistory();
  return (
    <Container>
      <Flex
        pb={{
          md: '100px',
          xs: '0'
        }}
        height={{
          md: '100vh',
          xs: 'auto'
        }}
        flexDirection="column"
        justifyContent={{
          md: 'center',
          xs: 'flex-start'
        }}
      >
        <Flex
          justifyContent={{
            md: 'space-between'
          }}
          flexDirection={{
            md: 'row',
            xs: 'column'
          }}
          padding={{
            md: '6px 0',
            xs: '20px 0'
          }}
        >
          <Flex
            width={{
              md: '50%',
              xs: '100%'
            }}
            textAlign={{
              md: 'left',
              xs: 'center'
            }}
            flexDirection="column"
            alignItems={{
              md: 'flex-start',
              xs: 'center'
            }}
          >
            <LanguageSwitcher
              active={lang}
              mt={5}
              onClick={handleLang}
            />
            <PseudoBox
              padding={{
                md: '40px 0 20px 0',
                xs: '30px 0 10px 0'
              }}
            >
              <Image
                src={Logo}
                width={{
                  md: '80px',
                  xs: '60px'
                }}
                alt=""
              />
            </PseudoBox>
            <Heading
              as="h1"
              fontSize={{
                md: '2.8rem',
                xs: '2rem'
              }}
              mb={6}
            >
              {getTranslation('title', lang)}
            </Heading>
            <PseudoBox
              fontSize={{
                md: '1.2rem',
                xs: '1rem'
              }}
              mb={4}
            >
              <Text>
                {getTranslation('subTitle', lang)}
              </Text>
            </PseudoBox>
            {/*<PseudoBox  mb={8}>
              <Link
                as={LinkRouter}
                fontSize="1rem"
                mr={3}
                color="blue.400"
                to={lang === 'en' ? '/faq/en' : '/faq'}
              >
                {getTranslation('faqLink', lang)}
              </Link>
            </PseudoBox>*/}
            <Grid mb={8} templateColumns={{
              md: '1fr 1fr 1fr',
              xs: '1fr 1fr'
            }} gap="10px">
              <AppButton
                type="appstore"
                link="https://apps.apple.com/ru/app/%D0%BF%D1%83%D1%82%D0%B5%D1%88%D0%B5%D1%81%D1%82%D0%B2%D1%83%D1%8E-%D0%B1%D0%B5%D0%B7-covid-19/id1541206996"
              />
              <AppButton
                type="googleplay"
                link="https://play.google.com/store/apps/details?id=com.nocovid19.su"
              />
              <AppButton
                link="https://appgallery.huawei.com/#/app/C104343239"
              />
            </Grid>
            <Flex
              fontSize="0.8rem"
              flexDirection={{
                md: 'row',
                xs: 'column'
              }}
              paddingBottom={{
                md: '0',
                xs: '40px'
              }}
            >
              {/*
                            <Link as={LinkRouter} mr={3} color="gray.400">Политика конфидициальности</Link>
                            <Link as={LinkRouter} to={'/privacy-policy'} color="gray.400">Privacy Policy</Link>
*/}

            </Flex>
          </Flex>
          <Flex
            justifyContent={{
              md: 'flex-start',
              xs: 'center'
            }}
            alignItems="center"
            mb={{
              md: '0px',
              xs: '40px'
            }}
          >
            <DeviceVideo lang={lang}/>
          </Flex>
        </Flex>
      </Flex>

    </Container>
  );
};
