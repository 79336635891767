import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { FaqRu } from './faq-ru';
import { FaqEn } from './faq-en';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.url}/en`}
      >
        <FaqEn/>
      </Route>
      <Route
        path={`${match.url}`}
      >
        <FaqRu/>
      </Route>
    </Switch>
  );
};

export default Routes;
